<script setup lang="ts">
import { useAddressStore } from '@/stores/address';
import { refDebounced, useBreakpoints, useVModel } from '@vueuse/core';
import { breakpoints } from '@glow/shared/lib/breakpoints';
import { NearIcon } from '@glow/shared/components/icons';
import { DEBOUNCE_INTERVAL_MS } from '@glow/shared/lib/constants';
import { useQuery } from '@urql/vue';
import { graphql } from '@/gql';

const props = defineProps<{
  modelValue?: boolean;
}>();
defineEmits<{
  (e: 'update:modelValue'): void;
}>();

const isVisible = useVModel(props, 'modelValue');
const addressStore = useAddressStore();
const address = ref(addressStore.address);
const isSuggestionListVisible = ref(false);
const addressDebounced = refDebounced(address, DEBOUNCE_INTERVAL_MS);

const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query AddressSelectModal_AddressSuggestionsQuery($query: String!) {
      addressSuggestions(query: $query) {
        id
        title
      }
      geoCode(query: $query) {
        id
        location
      }
    }
  `),
  variables: computed(() => ({
    query: addressDebounced.value,
  })),
  pause: toRef(
    () => !isVisible.value || (addressDebounced.value?.length ?? 0) < 3
  ),
});
const addressSuggestionTitles = computed(() =>
  queryData.value?.addressSuggestions.map((suggestion) => suggestion.title)
);
const isMobile = useBreakpoints(breakpoints).smaller('md');
const os = useOperatingSystem();
const mapMarkerLocation = toRef(() => queryData.value?.geoCode[0]?.location);

function onSuggestionSelect(suggestion: string) {
  address.value = suggestion;
  isSuggestionListVisible.value = false;
}
function onConfirm() {
  addressStore.address = address.value;
  isVisible.value = false;
}
</script>

<template>
  <AppModal v-model="isVisible" title="Укажите адрес" size="lg">
    <template #overlay>
      <SimpleMap
        v-if="isMobile"
        class="overlay-map"
        :marker-location="mapMarkerLocation"
      />
    </template>
    <div class="address-select">
      <div class="address-select__input">
        <AppAutocomplete
          v-if="!isMobile"
          v-model="address"
          :items="addressSuggestionTitles"
          autofocus
          fluid
          placeholder="Ваш адрес"
        >
          <template #prepend>
            <AppIcon :icon="NearIcon" color="accent" />
          </template>
        </AppAutocomplete>
        <!-- Autofocus does terrible things on IOS, so we won't have to use it here -->
        <AppInput
          v-else
          v-model="address"
          placeholder="Ваш адрес"
          :autofocus="os !== 'ios'"
          fluid
          @focus="isSuggestionListVisible = true"
        >
          <template #prepend>
            <AppIcon :icon="NearIcon" color="accent" />
          </template>
        </AppInput>
        <AppButton v-if="!isMobile" @click="onConfirm">Выбрать</AppButton>
      </div>

      <SimpleMap
        v-if="!isMobile"
        class="address-select__map"
        :marker-location="mapMarkerLocation"
      />
      <AppGroup v-else-if="isSuggestionListVisible" fluid>
        <div
          v-for="title in addressSuggestionTitles"
          :key="title"
          class="address-suggestion"
          @click="onSuggestionSelect(title)"
        >
          {{ title }}
        </div>
      </AppGroup>
      <AppButton v-else @click="onConfirm">Выбрать</AppButton>
    </div>
  </AppModal>
</template>

<style scoped lang="scss">
.overlay-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
}

.address-suggestion {
  padding: var(--app-spacing-2) 0;
}

.address-select {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;

  &__input {
    display: flex;
    gap: 24px;
  }

  &__map {
    height: 300px;
  }
}
</style>
